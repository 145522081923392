:root {
  --main-color: #123c5f;
  --secondary-color: #ffcb30;
}

body {
  font-family: sans-serif;
  color: var(--main-color);
  font-size: 24px;
  max-width: 1600px;
  margin: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  padding: 0;
  list-style-type: none;
}

.footer-content {
  display: flex;
  flex-direction: column;
  padding: 30px;
  max-width: 1200px;
  align-items: center;
  margin: auto;
}

.footer-content > * {
  margin-bottom: 35px;
}

.sm-list {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-evenly;
}

.footer-sections {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
}

.footer-sections > * {
  margin-left: 10px;
  margin-right: 10px;
}

.footer-footnote {
  display: flex;
}

.footer-goals {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  margin-top: 10px;
  flex-wrap: wrap;
}

.footer-goals > img {
  width: 100px;
  height: 100px;
  margin-top: 8px;
}

@media (max-width: 800px) {
  .footer-sections {
    flex-direction: column;
  }

  .footer-sections > * {
    margin-bottom: 16px;
    text-align: center;
  }

  .footer-footnote {
    flex-direction: column;
    align-items: center;
  }

  .footer-footnote > * {
    text-align: center;
  }
}

footer .fab {
  font-size: 21px;
  margin-bottom: 30px;
}
/* 
.images-wrap > div {
  width: 500px !important;
  height: 500px !important;
} */